import { Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const Splash = () => {
  return (
    <>
      <Grid container className="splashWrapper">
        <Grid item xs={1}></Grid>
        <Grid item xs={10} className="splashMiddle">
          <Typography component="h1" my={1}>
            GEARS AND TRANSMISSIONS
          </Typography>
          <Typography component="p" mb={3}>
            At Lata Engineering, we offer an extensive selection of gears and
            transmissions, sourced from the most reputable manufacturers around
            the globe. Enjoy the benefits of cost-effective solutions, on-time
            delivery, and unparalleled quality with Lata Engineering. We offer
            the following types of gears and transmissions services:
          </Typography>
        </Grid>
        <Grid item xs={1} className="splashRightBg"></Grid>
      </Grid>
    </>
  );
};

export default Splash;
