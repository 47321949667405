import {
  Box,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import Swal from "sweetalert2";
import CustomButton from "./CustomButton";
import emailjs from "emailjs-com";
import CustomTextField from "./CustomFieldFooter";

const Footer = () => {
  const [isSending, setIsSending] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setIsSending(true);
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
      formatted_date_time: new Date().toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
    };

    try {
      await emailjs.send(
        "service_n5qqgh8", // Replace with your EmailJS service ID
        "template_j4craac", // Replace with your EmailJS template ID
        templateParams,
        "8VoPg_U-U90JIy6Xv" // Replace with your EmailJS user ID
      );

      Swal.fire({
        icon: "success",
        title: "Message Sent",
        text: "Thank you for contacting us! We will get back to you shortly.",
      }).then(() => {
        // Reset form data
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      });
    } catch (error) {
      console.error(error);

      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "Your message could not be sent. Please try again or contact the admin.",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Grid container className="footerWrapper">
      <Grid item xs={12} lg={6} className="aboutUsWrapper" py={4}>
        <Grid container>
          <Grid item xs={1} lg={2}></Grid>
          <Grid item xs={10} lg={10}>
            <Typography component="h1" id="about">About Us</Typography>
            <Typography component="p" pr={2} mt={2}>
              At Lata Engineering, based in Nairobi, Kenya, we are dedicated to
              providing top-tier gears and transmissions tailored for optimal
              machinery performance. Our extensive inventory includes
              high-quality sprockets, gears, and transmission components sourced
              from trusted global manufacturers. Whether you need spur, helical,
              bevel, or worm gears, or various sprocket types, we ensure
              cost-effective solutions without compromising on quality. Though
              we are located in Nairobi, Kenya, we ship from various parts of
              the world to meet your needs.
            </Typography>
            <Typography component="p" pr={2} mt={2}>
              Our commitment to on-time delivery and exceptional service makes
              us a preferred choice for businesses seeking reliable and
              efficient mechanical components. Specializing in a broad range of
              geared products, including hydraulic gear pumps and reduction
              gearboxes, Lata Engineering stands out for its expertise in
              delivering precise and durable solutions. From rack and pinion
              gears to crown wheel and pinion gears, our offerings cater to
              diverse industrial needs. Order directly through our website or
              via WhatsApp (+254 721 219 065) for a seamless purchasing experience. Trust Lata
              Engineering in Nairobi, Kenya, to enhance your machinery with our
              superior gear and transmission solutions.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={6} className="contactUsWrapper" py={4}>
        <Grid container>
          <Grid item xs={12} lg={10}>
            <Typography component="h1" id="contact">Contact Us</Typography>
            <Box p={2}>
              <form onSubmit={handleSubmit}>
                <CustomTextField
                  placeholder="Your Names"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="normal"
                />
                <CustomTextField
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="normal"
                />
                <CustomTextField
                  placeholder="Phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin="normal"
                />
                <CustomTextField
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  multiline
                  rows={4}
                  fullWidth
                  margin="normal"
                />
                <Box my={2}>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isSending}
                    disableElevation
                    fullWidth
                  >
                    {isSending ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "SEND MAIL"
                    )}
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
          <Grid item xs={1} lg={2}></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
