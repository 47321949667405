import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const HelmetStuff = () => {
  return (
    <>
      <Helmet>
        {/* Page Title */}
        <title>Gears and Transmissions in Nairobi, Kenya - Lata Engineering</title>

        {/* Meta Description */}
        <meta 
          name="description" 
          content="Lata Engineering offers high-quality gears and transmission components including spur gears, helical gears, bevel gears, worm gears, hydraulic gear pumps, and reduction gearboxes in Nairobi, Kenya." 
        />

        {/* Meta Keywords */}
        <meta 
          name="keywords" 
          content="gears in Nairobi, gears Kenya, transmissions Nairobi, transmission components Kenya, spur gears, helical gears, bevel gears, worm gears, hydraulic gear pumps, reduction gearboxes, Lata Engineering" 
        />

        {/* Meta Author */}
        <meta name="author" content="Lata Engineering" />

        {/* OpenGraph Meta Tags (for social sharing) */}
        <meta property="og:title" content="Gears and Transmissions in Nairobi, Kenya - Lata Engineering" />
        <meta property="og:description" content="Find quality gears, transmissions, hydraulic gear pumps, and reduction gearboxes at Lata Engineering in Nairobi, Kenya. Explore our wide range of industrial solutions." />
        {/* <meta property="og:image" content="URL_to_your_image" /> */}
        <meta property="og:url" content="https://lata.co.ke" />
        <meta property="og:type" content="website" />

        {/* Canonical Link */}
        <link rel="canonical" href="https://lata.co.ke" />
      </Helmet>
    </>
  );
};

export default HelmetStuff;
