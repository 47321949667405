import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomCheckbox from "./CustomCheckbox";
import ProductWrapper from "./ProductWrapper";

const ProductSection = (props) => {
  const [listOfProducts, setListOfProducts] = useState(
    props.importedListOfProducts
  );

  const [allChecked, setAllChecked] = useState(true);
  const [selectedValue, setSelectedValue] = useState("all"); // Set default value to 'all'

  // Filtered products
  const selectedProducts = Object.entries(listOfProducts).filter(
    ([key, product]) => product.checked
  );

  // Handle individual radio change
  const handleRadioChange = (event) => {
    const gear = event.target.value;

    // appearance of selected gear
    setSelectedValue(gear);

    if (gear === "all") {
      // set listOfProducts check key to true for all
      const updatedProducts = Object.keys(listOfProducts).reduce((acc, key) => {
        acc[key] = {
          ...listOfProducts[key],
          checked: true, // Set checked to true for all items
        };
        return acc;
      }, {});
      setListOfProducts(updatedProducts);
    } else {
      const updatedProducts = Object.keys(listOfProducts).reduce((acc, key) => {
        acc[key] = {
          // Add a new key-value pair to the accumulator object (acc)
          ...listOfProducts[key], // Copy the existing product data
          checked: key === gear, // Set checked to true if the key matches 'gear', otherwise false
        };
        return acc; // Return the updated accumulator object after each iteration
      }, {});

      setListOfProducts(updatedProducts);
    }
  };

  return (
    <>
      <Grid container className="">
        <Grid item xs={1}></Grid>
        <Grid item xs={10} container alignItems="stretch">
          <Grid
            item
            xs={12}
            lg={4}
            className="leftMenuWrapper"
            p={2}
            sx={{
              pl: { xs: 3},
            }}
          >
            {/* Content for the left column */}

            <FormControl>
              <RadioGroup
                value={selectedValue} // Bind the selected value to RadioGroup
                onChange={handleRadioChange} // Handle changes
              >
                <FormControlLabel
                  value="all"
                  control={
                    <Radio
                      sx={{
                        color: "white", // Unchecked color
                        "&.Mui-checked": {
                          // Checked color
                          color: "white",
                        },
                      }}
                    />
                  }
                  label="All"
                  sx={{ color: "white" }} // Label color
                />
                {Object.entries(listOfProducts).map(([key, product]) => (
                  <FormControlLabel
                    key={key}
                    value={key}
                    control={
                      <Radio
                        sx={{
                          color: "white", // Unchecked color
                          "&.Mui-checked": {
                            // Checked color
                            color: "white",
                          },
                        }}
                      />
                    }
                    label={product.title}
                    sx={{ color: "white" }} // Label color
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            className="rightMenuWrapper"
            py={2}
            sx={{
              pl: { xs: 0, lg: 4 },
            }}
          >
            {selectedProducts.map(([key, product]) => (
              <ProductWrapper key={key} product={product} />
            ))}
          </Grid>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </>
  );
};

export default ProductSection;
