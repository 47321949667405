import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2EA3FC',
    },
    secondary: {
      main: '#2A2A2A',
    },
  },
});

export default theme;
