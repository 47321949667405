import React, { useState, useEffect } from "react";
import { styled } from '@mui/system';
import { Button } from "@mui/material";

// Define the custom styled TextField
const CustomBtn = styled(Button)({
  color: '#FFF', // Text color
  backgroundColor: '#2ea3fc', // Background color
  '&:hover': {
    backgroundColor: '#FFF', // Background color
    color: "#2ea3fc",
  },
  borderRadius: 0,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none', // Remove border
    },
  },
});

const CustomButton = (props) => {
  return (
    <>
      <CustomBtn 
        value="Custom Btn"
        fullWidth 
        size="large"
        disableElevation
        >
          {props.text}</CustomBtn>
    </>
  );
};

export default CustomButton;
