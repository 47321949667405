import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import CustomButton from "./CustomButton";
import emailjs from "emailjs-com";

const ModalForm = ({ productToOrder, handleClose }) => {
  const [isSending, setIsSending] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    setIsSending(true);
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      phone: formData.phone,
      email: formData.email,
      product: productToOrder.title,
      message: `The customer is interested in the following product: ${productToOrder.title}.`,
      formatted_date_time: new Date().toLocaleString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
    };

    try {

      await emailjs.send(
        "service_n5qqgh8", // Replace with your EmailJS service ID
        "template_wdgoicg", // Replace with your EmailJS template ID
        templateParams,
        "8VoPg_U-U90JIy6Xv" // Replace with your EmailJS user ID
      );

      handleClose();
      Swal.fire({
        icon: "success",
        title: "Order Placed",
        text: "Your order has been successfully submitted!",
      });

    } catch (error) {
      handleClose();
      Swal.fire({
        icon: "error",
        title: "Submission Failed",
        text: "Your order could not be submitted. Please try again of contact the admin (email: thubikm@gmail.com or call: +254 721 219 065) .",
      });
    }
  };

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        align="center"
      >
        Place Order For {productToOrder.title}
      </Typography>

      <form onSubmit={handleSubmit}>
        <Box
          my={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          // Ensure the parent container has a height to center vertically
          height="200px"
          sx={{overflow: "hidden" }} 
        >
          <img
            src={"/" + productToOrder.image}
            style={{ maxWidth: "150px", width: "100%", objectFit: "contain", maxHeight: "100%" }}
            alt="Product"
          />
        </Box>
        <Box my={2}>
          <TextField
            id="outlined-basic"
            label="Your Name"
            variant="outlined"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>
        <Box my={2}>
          <TextField
            id="outlined-basic"
            label="Phone"
            variant="outlined"
            type="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>
        <Box my={2}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            fullWidth
          />
        </Box>
        <Box my={2}>
          <Button
            variant="contained"
            type="submit"
            disabled={isSending}
            disableElevation
            fullWidth
          >
            {isSending ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "SEND MAIL"
            )}
          </Button>
        </Box>
        <Box my={2}>
          <Button variant="outlined" onClick={handleClose} fullWidth>
            CANCEL
          </Button>
        </Box>
      </form>
    </>
  );
};

export default ModalForm;
