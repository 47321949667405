import { Container, Grid, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./App.scss";
import HeaderSection from "./components/HeaderSection";
import Splash from "./components/Splash";
import Heading from "./components/Heading";
import ProductSection from "./components/ProductSection";
import Footer from "./components/Footer";
import { gears, gearedProducts, gearBoxDesign } from "./productData";
import HelmetStuff from "./components/HelmetStuff";
// import ResponsiveAppBar from "./components/ResponsiveAppBar";

const App = () => {
  const theme = useTheme();

  return (
    <>
      {/* <ResponsiveAppBar /> */}
      <HelmetStuff />
      <HeaderSection />
      <Splash />
      <Heading title="High-Quality Sprockets, Gears & Transmissions for Efficient Machinery" id="gears" />
      <ProductSection importedListOfProducts={gears} all="All Gears" />
      <Heading title="Geared Products" id="gearedProducts" />
      <ProductSection importedListOfProducts={gearedProducts} all="All Geared Products" />
      <Heading title="Gear and Gear Box Design" id="gearBoxDesign" />
      <ProductSection importedListOfProducts={gearBoxDesign} all={false} />
      <Footer />
    </>
  );
};

export default App;
