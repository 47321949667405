export const gears = {
  SpurAndHelical: {
    title: "Spur and Helical Gears",
    description:
      "At Lata Engineering, our Spur and Helical Gears are crafted for precision and efficiency in power transmission. Spur gears are ideal for simple, straightforward applications, delivering reliable, high-torque performance. Helical gears, with their angled teeth, offer smoother, quieter operation, especially at higher speeds. Together, they provide versatile solutions for various industrial needs, ensuring optimal performance and durability.",
    image: "spur-helical.png",
    checked: true,
  },
  RackAndPinion: {
    title: "Rack and Pinion Gears",
    description:
      "Our Rack and Pinion Gears are engineered for accuracy and efficiency in linear motion applications. The precise engagement between the rack and pinion provides reliable, smooth operation, making these gears ideal for steering systems, automation equipment, and other applications requiring controlled motion.",
    image: "rack-and-pinion.png",
    checked: true,
  },
  BevelGears: {
    title: "Bevel Gears",
    description:
      "Lata Engineering offers premium Bevel Gears designed for dependable angular power transmission. Our bevel gears feature a robust design that handles significant loads, making them perfect for machinery requiring smooth, right-angle gear shifts. Available in straight, spiral, and hypoid configurations, they ensure efficient performance across diverse applications.",
    image: "bevel-gear.png",
    checked: true,
  },
  WormGears: {
    title: "Worm Gears",
    description:
      "Our Worm Gears are built for applications requiring high torque and reduced speed. The unique worm-and-gear configuration provides self-locking capabilities, making them ideal for lifting and hoisting systems. Crafted from durable materials, these gears ensure long-lasting, quiet, and smooth operation in demanding environments.",
    image: "worm-gear.png",
    checked: true,
  },
  CrownWheelAndPinion: {
    title: "Crown wheel and Pinion Gears",
    description:
      "For optimal differential performance, Lata Engineering provides precision-engineered Crown Wheel and Pinion Gears. These gears ensure smooth, efficient torque transfer between the drive shaft and the wheels, making them crucial components in automotive and heavy machinery applications. Designed for durability and consistent performance, our crown wheel and pinion sets are built to last.",
    image: "crown-wheel-pinion.png",
    checked: true,
  },
  Sprockets: {
    title: "Sprockets (Simplex/Duplex)",
    description:
      "Our Simplex and Duplex Sprockets are engineered for seamless power transfer in chain-driven systems. Whether you're working with light or heavy loads, our sprockets are designed for durability and efficient performance. The precise tooth profile ensures optimal engagement with the chain, reducing wear and extending operational life.",
    image: "sprockets.png",
    checked: true,
  },
};

export const gearedProducts = {
  HydraulicGear: {
    title: "Hydraulic Gear Pump",
    description:
      "Lata Engineering’s Hydraulic Gear Pumps are designed for efficiency and reliability in fluid power systems. These pumps are engineered to deliver consistent flow and pressure, ensuring smooth operation across various hydraulic applications. With their robust design and high-quality materials, our gear pumps are built to perform under the most demanding conditions.",
    image: "hydraulic-gear-box.png",
    checked: true,
  },
  ReductionGear: {
    title: "Reduction Gear Boxes",
    description:
      "Our Reduction Gear Boxes provide precise control of speed and torque in machinery. Designed for high efficiency and durability, these gearboxes are perfect for applications requiring significant power reduction. With options for various configurations, Lata Engineering ensures that you get the right gearbox solution for your specific needs.",
    image: "reduction-gear-box.png",
    checked: true,
  },
};

export const gearBoxDesign = {
  GearBoxDesign: {
    title: "Gear and Gear Box Design Services",
    description:
      "At Lata Engineering, we offer expert Gear and Gear Box Design Services using state-of-the-art tools like AutoDesk Inventor and Fusion 360. Our skilled engineers work closely with clients to create custom designs tailored to specific operational requirements. Whether you need a new gear system or modifications to existing designs, we deliver innovative, high-performance solutions.",
    image: "gear-design.png",
    checked: true,
  },
};
