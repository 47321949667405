import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const Heading = (props) => {
  return (
    <>
      <Grid container className="heading" id={props.id}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Box py={1} className="">
            <Typography component="h3">{props.title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </>
  );
};

export default Heading;
