import React, { useState } from "react";
import { Grid, Container, Typography, Link, Stack, Box } from "@mui/material";
import { Helmet } from 'react-helmet';

import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dom } from "@fortawesome/fontawesome-svg-core";

dom.watch();

const HeaderSection = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {/* desktop menu */}
      <Grid container sx={{ display: { xs: "none", lg: "flex" } }}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10} sx={{ position: "relative" }}>
          <Box
            className="top"
            sx={{ paddingLeft: "352px", textAlign: "right" }}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Box className="menuWrapper">
              <span>EMAIL: info@lata.co.ke | thubikm@gmail.com</span>
              <span>TEL: +254 721 219 065</span>
              <span>
                <a target="_blank" href="https://www.facebook.com/profile.php?id=61566381935459">
                  <img src="/fb-icon.svg" className="fbLogo" />
                </a>
              </span>
            </Box>
          </Box>
          <Box
            className="bottom"
            sx={{ paddingLeft: "352px", textAlign: "right" }}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Box className="menuWrapper bottomWrapperMenu">
              <span>
                <a href="#gears" className="active">
                  GEARS
                </a>
              </span>
              <span>
                <a href="#gearedProducts">Geared Products</a>
              </span>
              <span>
                <a href="#gearBoxDesign">Gear & Gear Box Design</a>
              </span>
              <span>
                <a href="#about">ABOUT US</a>
              </span>
              <span>
                <a href="#contact">CONTACT US</a>
              </span>
            </Box>
          </Box>
          <Box
            className="logoWrapper"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              background: "none",
              width: "352px",
            }}
          >
            <a href="/">
              <img src="/lata-logo.png" className="logo" />
            </a>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box className="top"></Box>
          <Box className="bottom"></Box>
        </Grid>
      </Grid>
      <div className="responsive-menu-wrapper">
        <Grid container sx={{ display: { xs: "flex", lg: "none" } }}>
          <Box className="contactWrapper" px={1}>
            <span>EMAIL: info@lata.co.ke | thubikm@gmail.com</span>
            <span>TEL: +254 721 219 065</span>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="10px"
            sx={{ width: "100%" }}
          >
            {/* Logo */}
            <a href="/">
              <img src="/lata-logo.png" alt="Logo" style={{ height: "80px" }} />
            </a>

            {/* Hamburger Menu Icon */}
            <Box className="hamburger" onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </Box>
          </Box>

          {/* Collapsible Menu */}
          <Box className={`menu ${menuOpen ? "open" : ""}`}>
            <Box className="menuWrapper">
              <a href="#gears">GEARS</a>
              <a href="#gearedProducts">GEARED PRODUCTS</a>
              <a href="#gearBoxDesign">GEAR & GEAR BOX DESIGN</a>
              <a href="#about">ABOUT US</a>
              <a href="#contact">CONTACT US</a>
            </Box>
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default HeaderSection;
