import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";

// Define the custom styled TextField
const CustomTextField = styled(TextField)({
  color: "#3D3D3D", // Text color
  backgroundColor: "#FFF", // Background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none", // Remove border
    },
  },
});

const CustomFieldFooter = (props) => {
  return (
    <>
      <CustomTextField
        placeholder={props.placeholder}
        fullWidth
        margin="normal"
        multiline={!!props.multiline}
        minRows={props.rows ? props.rows : 0}
        required={!!props.required}

        onChange={props.onChange} // Handle the change here
        value={props.value} // Pass the value prop
        name={props.name} // Pass the name prop
      />
    </>
  );
};

export default CustomFieldFooter;
