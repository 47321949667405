import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import ModalForm from "./ModalForm";

const ProductWrapper = (props) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const [productToOrder, setProductToOrder] = useState({});
  const handleOpen = (product) => {
    setOpen(true);
    setProductToOrder(product);
  };


  const handleClose = () => setOpen(false);

  const handleWhatsAppOrder = (product) => {
    const message = `Hello, I am interested in the product: ${product.title}`;
    const phoneNumber = "+254721219065";
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    window.open(whatsappUrl, "_blank");
  };

  return (
    <>
      <Grid className="productWrapper" container my={2}>
        <Grid item xs={3} className="imageBox">
          <div className="innerBox">
            <img src={'/'+props.product.image} />
          </div>
        </Grid>
        <Grid item xs={9} className="descriptionBox" px={2} py={1} pb={2}>
          <Typography component="h1" sx={{ textTransform: 'uppercase' }}>{props.product.title}</Typography>
          <Typography
            component="p"
            my={1}
            sx={{ fontSize: 14, fontWeight: 400 }}
          >
            {props.product.description}
          </Typography>

          <Grid container spacing={2} mt={1}>
            <Grid item>
              <Button
                onClick={() => {
                  handleOpen(props.product);
                }}
                disableElevation
                variant="contained"
                size="small"
                endIcon={<FontAwesomeIcon icon={faShoppingCart} />}
                sx={{
                  borderRadius: 0,
                  color: "white",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                }}
              >
                Place Order Now
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => handleWhatsAppOrder(props.product)}
                disableElevation
                variant="contained"
                size="small"
                endIcon={<FontAwesomeIcon icon={faWhatsapp} />}
                sx={{
                  borderRadius: 0,
                  backgroundColor: "#25D366",
                  "&:hover": { backgroundColor: "#1DB954" },
                  color: "white",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                }}
              >
                Order Via Whatsapp
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ModalForm
            productToOrder={productToOrder}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      {/* end modal */}
    </>
  );
};

export default ProductWrapper;
